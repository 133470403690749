:root {
  --user_self: white;
  --user_mod: white;
  --user: black;
}

#root > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
}

div::-webkit-scrollbar {
  width: 5px;
}

div::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  /* border-radius: 10px; */
}

div::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 100, 100);
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb:active {
  background: rgb(68, 68, 68);
  border-radius: 10px;
}

.styles_scrollable-div__prSCv {
    display: flex;
    flex-direction: column;
}

.vfwmessenger__msgButtons {
  display: none;
  position: absolute;
  top: 5px;
  right: 1rem;
}

.vfwmessenger__msgButtons button {
    color: #f87171;
    background-color: #fff;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    padding: 4px;
    display: flex;
    border-radius: 5px;  
}

.vfwmessenger__msgButtons svg {
  width: 15px;
}

.vfwmessenger__msg__container {
  height: 100%;
  width: 100%;
  margin: auto;
  border-bottom: 1px solid #019cdc;
  overflow-y: auto;
  overflow-x: hidden;
}

p.vfwmessenger__msg {
    position: relative;
    overflow-wrap:break-word;
    padding: 0.5rem;
    color: var(--user);
    background-color: #f3f4f6;
    width: calc(100% - 3rem);
    display: block;
    margin: 0.5rem auto 0.5rem 0;
    border-radius: 10px;
    border:solid #fff;
}

p.vfwmessenger__msg.vfwmessenger__user--self {
    background-color: #019cdc;
    color: var(--user_self);
    margin: 0.5rem 0 0.5rem auto;
}
p.vfwmessenger__msg.vfwmessenger__user--self.vfwmessenger__user--mod {
    background-color: #019cdc;
    color: var(--user_mod);
    margin: 0.5rem 0 0.5rem auto;
}
p.vfwmessenger__msg.vfwmessenger__user--mod {
    /*background-color: #353846;*/
    background-color: #9ca3af;
    color: var(--user_mod);
}

p.vfwmessenger__msg--deleted {
  color: #6d6d6d;
}

p.vfwmessenger__msg:hover > .vfwmessenger__msgButtons, .vfwmessenger__msgButtons:hover {
  display: inline-block;
}

span.vfwmessenger__user {
    display: block;
}

.vfwmessenger__user {
  font-weight: bold;
  color: var(--user);
}

.vfwmessenger__user--mod {
  color: var(--user_mod);
}

.vfwmessenger__user--self {
  color: var(--user_self);
}

.vfwmessenger__msgi__container {
  margin-top: 10px;
}

.vfwmessenger__msgi__form {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
  gap: 3px;
}

.vfwmessenger__msgi__button {
  font-size: 1rem;
  font-weight: bold;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  position: absolute;
  right: 5px;
  margin-top: 8px;
  color: #019cdc;
}

.vfwmessenger__msgi__button svg {
  width: 24px;
}

.vfwmessenger__msgi__textarea {
  resize: none;
  border: 1px solid #b3b1b1;
  padding: 10px;
  padding-right: 45px;
  width: inherit;
  outline: none;
  font-size: 1rem;
  border-radius: 5px;
}

/*.vfwmessenger__msgi__textarea:focus {
  border: 2px solid black;
}*/



